<!-- 登录 -->
<template>
  <div>
    <van-nav-bar
      :title="$t('login.title2')"
      :left-text="$t('main.return')"
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 登录 -->
    <div id="login" ref="login" style="height:100%">
      <div class="box-part">
        <!-- 表单 -->
        <div class="title">
          <van-icon name="manager" class="color-blue" />
          {{ $t('main.accountLogin') }}
        </div>
        <div class="form">
          <van-form validate-first input-align="left" label-width="0px">
            <van-cell-group>
              <van-field
                v-model="loginForm.username"
                label=" "
                :placeholder="$t('login.user-name')"
                label-width="0px"
                clearable
              />
            </van-cell-group>
            <van-cell-group>
              <van-field
                v-model="loginForm.password"
                label=" "
                :placeholder="$t('login.psw')"
                label-width="0px"
                clearable
                type="password"
              />
            </van-cell-group>
            <van-cell-group>
              <van-field
                v-model="loginForm.verifycode"
                maxlength="4"
                label=" "
                :placeholder="$t('login.v-code')"
                label-width="0px"
                clearable
              >
                <template #button>
                  <div class="login-code2" @click="refreshCode">
                    <s-identify :identify-code="identifyCode" />
                  </div>
                </template>
              </van-field>
            </van-cell-group>
            <!-- 按钮组 -->
            <div class="btn-group">
              <van-button
                block
                type="info"
                native-type="submit"
                class="btn-class submit"
                @click="goToLogin"
              >
                {{ $t('login.login') }}
              </van-button>
              <van-button
                block
                type="warning"
                native-type="submit"
                class="btn-class submit"
                @click="toRegister()"
              >
                {{ $t('login.register') }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import Config from '@/settings'
import SIdentify from '@/components/Identify'
// import SIdentify2 from '@/components/Identify2'
import { Toast } from 'vant'
import { NavBar } from 'vant'
import Vue from 'vue'
import md5 from 'js-md5'
Vue.prototype.$md5 = md5
export default {
  name: 'Login',
  components: {
    SIdentify,
    [NavBar.name]: NavBar
  },
  data() {
    return {
      language: '',
      mainShow: true,
      signUpShow: false,
      registerShow: false,
      imageList: [
        require('../../assets/newImages/login5.png'),
        require('../../assets/newImages/login2.png'),
        require('../../assets/newImages/login4.png'),
        require('../../assets/newImages/login3.png'),
        require('../../assets/newImages/login1.png')
      ],
      loginForm: {
        username: '',
        password: '',
        verifycode: '',
        loginStatus: false,
        accountType: 'BASE'
      },
      // 验证码随机数集合
      identifyCodes: '1234567890',
      // 定义验证码
      identifyCode: ''
    }
  },
  computed: {
    passwordIcon: function() {
      return this.passwordType === 'password' ? 'closed-eye' : 'eye'
    }
  },
  beforeMount() {
    document.querySelector('body').setAttribute('style', 'background: #fff')
    console.log(11)
  },
  mounted() {
    // 验证码初始化
    this.identifyCode = ''
    // 随机获取4位长度的验证码
    this.makeCode(this.identifyCodes, 4)
    this.setLanguage()
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: '/' })
    },
    // 初始化语言
    setLanguage() {
      if (window.localStorage.getItem('language') === 'cn') {
        this.language = 'English'
      } else if (window.localStorage.getItem('language') === 'en') {
        this.language = '中文'
      } else {
        this.language = 'English'
      }
    },
    // 更改语言
    changeLanguage() {
      if (window.localStorage.getItem('language') === 'cn') {
        window.localStorage.setItem('language', 'en')
        this.$i18n.locale = window.localStorage.getItem('language')
        this.language = '中文'
        this.$Local('en')
      } else if (window.localStorage.getItem('language') === 'en') {
        window.localStorage.setItem('language', 'cn')
        this.$i18n.locale = window.localStorage.getItem('language')
        this.language = 'English'
        this.$Local('cn')
      }
    },
    changeView() {
      this.$router.push('/signUp')
      // if (value === 'registerShow') {
      //   this.mainShow = false
      //   this.signUpShow = false
      //   this.registerShow = true
      // } else if (value === 'signUpShow') {
      //   this.mainShow = false
      //   this.registerShow = false
      //   this.signUpShow = true
      // }
    },
    toSign() {
      this.$router.push({ path: 'signUp' })
    },
    toRegister() {
      this.$router.push({ path: 'register' })
    },
    // 验证码自定义验证规则
    validateVerifycode() {
      if (this.loginForm.verifycode === '') {
        Toast.fail(this.$t('msg.writeCode'))
        return false

        // 请输入验证码
      } else if (this.loginForm.verifycode !== this.identifyCode) {
        Toast.fail(this.$t('msg.wrongCode'))
        // 验证码不正确
        return false
      } else {
        return true
      }
    },
    goToLogin() {
      const verifycodePass = this.validateVerifycode()
      if (verifycodePass === true) {
        if (
          this.loginForm.username === null ||
        this.loginForm.password === null ||
        this.loginForm.username === '' ||
        this.loginForm.password === ''
        ) {
          Toast.fail(this.$t('msg.login'))
          return false
        } else {
          var param = JSON.parse(JSON.stringify(this.loginForm))
          param.password = this.$md5(this.loginForm.password)
          // console.log(param)
          param.loginStatus = Boolean(param.loginStatus)
          if (param.loginStatus) {
            Cookies.set('username', param.username, { expires: Config.passCookieExpires })
            Cookies.set('password', param.password, { expires: Config.passCookieExpires })
            Cookies.set('loginStatus', param.loginStatus, { expires: Config.passCookieExpires })
          } else {
            Cookies.remove('username')
            Cookies.remove('password')
            Cookies.remove('loginStatus')
          }
          // console.log(param)
          // alert(333333)
          this.$store.dispatch('user/login', param).then(() => {
            Toast.success(this.$t('msg.loginSuccess'))
            this.$router.push({ path: this.redirect || '/home' })
            this.$store.dispatch('user/getInfo')
          })
        }
      }
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 生成四位随机验证码
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.van-button{
  font-size: 16px;
}
.login-code2{
  height: 24px;
  position: relative;
  top:-4px;
}
.box-part{
  margin: 10px 30px;
  box-shadow:0px 0px 10px 0px rgba(47,159,242,0.15);
  border-radius:4px;
  padding: 30px 22px;
  .title{
    display: flex;
    align-items: center;
    font-size: 16px;
    .color-blue{
      color: #2F9FF2;
      margin-right: 5px;
    }
  }
}
.top{
  margin-top:38px;
  .imgClass{
    display: block;
    width: 92px;
    height: 33px;
  }
  .session{
    margin-top:10px ;
    .left-text{
      margin-top:45px;
      margin-left:8px;
      .text-first{
        font-size: 24px;
        font-weight: bold;
       }
      .text-second{
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
      }
    }
  }
}
.form{
  margin-top:30px;
}
.van-cell-group{
  border-bottom:1px solid #D0D0D0
}
.btn-group{
  margin-top:40px;
  .btn-class{
    margin: 10px 0;
  }
  .btn-text{
    color: #2F9FF2;
  }
}
.engLish{
  margin-top:20px;
  font-weight:bold !important;
  cursor: pointer;
  height:16px;
  font-size:16px;
  font-family:STHeitiSC-Light,STHeitiSC;
  font-weight:300;
  color:#2F9FF2;
  line-height:16px;
}
.bottom{
  width: 100%;
  padding:0 30px;
  height: 100px;
  margin-top:40px;
}
.border-dash-middle{
  border-bottom:1px dashed #D0D0D0;
  position: relative;
}
.login-other{
  text-align: center;
  width:120px;
  padding: 0 10px;
  position: absolute;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  font-size:14px;
  font-family:STHeitiSC-Medium,STHeitiSC;
  font-weight:500;
  color:rgba(208,208,208,1);
  line-height:14px;
  background: #fff;
}
.link{
  display: flex;;
  margin: 20px auto 0;
  .flex{
    flex: 1;
    justify-content:center;
    .link-img{
    // margin:20px;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    height: 40px;
    width: 40px;
    }
  }
}
.right-img{
  width: 150px;
  height: 121px;
}
.van-hairline--top-bottom {
    position: relative;
    top: -2px
}
.van-cell-group {
    border-bottom: 0.02667rem solid #D0D0D0;
}
.van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after {
    border-width: 0;
}
.van-hairline--bottom::after{
  border: 0;
}
.van-nav-bar__left{
  color: #747474;
}
.van-button--info{
  background-color: #2F9FF2;
}
.van-button--warning{
  background-color: #FFFFFF;
  border: 1px solid #2F9FF2;
  color: #2F9FF2;
}
.btn-class{
  border-radius: 4px;
}
::v-deep .van-nav-bar .van-icon{
  color: #747474;
}
::v-deep .van-nav-bar__text{
  color: #747474;
}
::v-deep .van-nav-bar{
  height: 70px
}
</style>
